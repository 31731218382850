import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { PureComponent } from 'react';

import { getCourseConfig } from 'my-core/course-utils';
import { formatDate, getSemester } from 'my-core/date-utils';
import { getStudySessionSemesterDate } from 'my-core/study-session-utils';
import { pluralize } from 'my-utils';

import OnlineCourseIcon from 'my-components/OnlineCourseIcon';
// import { numberToCurrency } from 'my-utils';
import MuiAvatar from 'my-elements/MuiAvatar';

import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

class SearchResult extends PureComponent {
  render() {
    const { result } = this.props;

    switch (result._type) {
      case 'online_course':
        return <OnlineCourseResult {...this.props} />;
      case 'user':
        return <UserResult {...this.props} />;
      case 'course':
        return <CourseResult {...this.props} />;
      case 'tag':
        return <TagResult {...this.props} />;
      case 'faq':
        return <FaqResult {...this.props} />;
      case 'school':
        return <SchoolResult {...this.props} />;
      case 'partner':
        return <PartnerResult {...this.props} />;
      case 'study_session':
        return <StudySessionResult {...this.props} />;
      case 'flash_card_deck':
        return <FlashCardDeckResult {...this.props} />;
      default:
        return <BaseSearchResult {...this.props} />;
    }
  }
}

const styles = ({ palette, spacing }) => ({
  itemSelected: {
    backgroundColor: palette.grey[200],
  },
  contentLeft: {
    flex: '0 0 auto',
    marginRight: spacing(1),
  },
  courseImage: {
    borderRadius: '50%',
    overflow: 'hidden',
    width: 40,
    height: 40,
  },
  otherContent: {
    marginLeft: spacing(1),
    whiteSpace: 'pre-line',
    flex: '0 0 auto',
  },
});

export default withStyles(styles)(SearchResult);

class BaseSearchResult extends PureComponent {
  render() {
    const {
      classes,
      highlighted,
      isAdmin,
      onResultClick,
      onSetHighlightedIdx,
      result,
      resultIdx,
      withAvatar,
      ...rest
    } = this.props;
    const primaryText = this.primaryText() || result.primaryText;
    const secondaryText = this.secondaryText() || result.secondaryText;
    const leftContent = !!withAvatar && this.leftContent();
    const secondaryAction = this.secondaryAction();
    const otherContent = this.otherContent();
    const disabled = !!result.resultDisabled;
    const Root = disabled ? ListItem : ListItemButton;
    return (
      <Root
        classes={{ selected: classes.itemSelected }}
        onClick={disabled ? undefined : () => onResultClick(result)}
        onMouseEnter={() => onSetHighlightedIdx(resultIdx)}
        selected={!disabled && highlighted}
        {...rest}
      >
        {leftContent}
        {Boolean(primaryText || secondaryText) && (
          <ListItemText
            primary={primaryText}
            primaryTypographyProps={{ color: 'inherit', ...result.primaryTextProps }}
            secondary={secondaryText}
            secondaryTypographyProps={{ color: 'textSecondary', ...result.secondaryTextProps }}
          />
        )}
        {otherContent && (
          <Typography className={classes.otherContent} color="textSecondary" variant="caption">
            {otherContent}
          </Typography>
        )}
        {secondaryAction && <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>}
      </Root>
    );
  }

  leftContent() {
    /* ABSTRACT METHOD */
  }

  primaryText() {
    /* ABSTRACT METHOD */
  }

  secondaryText() {
    /* ABSTRACT METHOD */
  }
  otherContent() {
    /* ABSTRACT METHOD */
  }

  secondaryAction() {
    /* ABSTRACT METHOD */
  }
}

class OnlineCourseResult extends BaseSearchResult {
  leftContent() {
    const { isAdmin, result } = this.props;
    const { classes } = this.props;
    if (isAdmin) {
      return (
        <OnlineCourseIcon className={classNames(classes.contentLeft, classes.courseImage)} onlineCourse={result} />
      );
    }
    return <CourseImage className={classNames(classes.contentLeft, classes.courseImage)} course={result} />;
  }
  primaryText() {
    const { result } = this.props;
    return `${result.course_code}: ${result.name}`;
  }
  secondaryText() {
    const { result } = this.props;
    return `(${result.school_name}) ${result.course_name}`;
  }
  otherContent() {
    const { dense, isAdmin, result } = this.props;
    if (isAdmin) {
      return result.assessment_date && formatDate(result.assessment_date, dense ? 'dateShortNum' : 'dateShort');
    }
  }
}

class CourseResult extends BaseSearchResult {
  primaryText() {
    const { result } = this.props;
    return result.code;
  }
  secondaryText() {
    const { result } = this.props;
    return [result.school_name && `(${result.school_name})`, result.name].filter(Boolean).join(' ');
  }
  leftContent() {
    const { classes, result } = this.props;
    return <CourseImage className={classNames(classes.contentLeft, classes.courseImage)} course={result} />;
  }
}

class PartnerResult extends BaseSearchResult {
  leftContent() {
    const { result } = this.props;
    return <Avatar src={result.logo.thumb} />;
  }
  primaryText() {
    const {
      result: { name },
    } = this.props;
    return name;
  }
}

class SchoolResult extends BaseSearchResult {
  primaryText() {
    const { result } = this.props;
    return result.name;
  }

  secondaryText() {
    const { result } = this.props;
    return [result.region_code, result.country_code].filter(Boolean).join(', ');
  }
}

class UserResult extends BaseSearchResult {
  leftContent() {
    const { classes, result } = this.props;
    return (
      <MuiAvatar
        avatar={result.has_avatar ? result.avatar : undefined}
        className={classNames(classes.contentLeft, classes.courseImage)}
        src="thumb"
      />
    );
    // return <Avatar src={result.avatar.thumb} className={classNames(classes.contentLeft, classes.courseImage)} />;
  }
  primaryText() {
    const {
      result: { name },
    } = this.props;
    return name;
  }
  secondaryText() {
    const { result } = this.props;
    const { isAdmin } = this.props;
    return `${isAdmin ? `${result.email} ` : ''} ${result.school_id ? `(${result.school_name}) ` : ''}`;
  }
}

class TagResult extends BaseSearchResult {
  primaryText() {
    const {
      result: { name },
    } = this.props;
    return name;
  }
}

class FaqResult extends BaseSearchResult {
  primaryText() {
    const {
      result: { question },
    } = this.props;
    return question;
  }
}

class StudySessionResult extends BaseSearchResult {
  primaryText() {
    const { result } = this.props;
    return result.title;
  }

  secondaryText() {
    const { result } = this.props;
    return result.courses.map(c => `(${c.school_short_name || c.school_name}) ${c.code}`).join(', ');
  }
  otherContent() {
    const { result } = this.props;
    const semesterDate = getStudySessionSemesterDate(result);
    return semesterDate && getSemester(semesterDate);
  }
}

class FlashCardDeckResult extends BaseSearchResult {
  primaryText() {
    const { result } = this.props;
    return result.title;
  }

  secondaryText() {
    const { result } = this.props;
    return pluralize(result.cards_count, 'card');
  }
}

function CourseImage({ className, course }) {
  const config = getCourseConfig(course);
  return <img className={className} src={config.icon} />;
}
